<template>
	<div class="">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-main ftw600">{{getTitle}}</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			
			<div class="mt20 bg-w pd40">
				<div style="width: 1000px;">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-form-model-item required label="次卡名称" help="20字以内">
							<a-input v-model="form.name" placeholder="输入次卡名称" style="width: 400px;"></a-input>
						</a-form-model-item>
						
							<a-form-model-item label="次卡售价" required>
								<a-input-number v-model="form.price" :formatter="value => `${value} 元`" :min="0" :precision="2" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="次卡总价值" required>
								<a-input-number v-model="form.worth" :formatter="value => `${value} 元`" :min="0" :precision="2" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<!-- <a-form-model-item required label="是否限时秒杀">
								<a-radio-group v-model="form.is_flash_sale">
									<a-radio :value="1">是</a-radio>
									<a-radio :value="0">否</a-radio>
								</a-radio-group>
							</a-form-model-item> -->
							
							<!-- <a-form-model-item label="秒杀价格" required v-if="form.is_flash_sale==1">
								<a-input-number v-model="form.flash_price" :formatter="value => `${value} 元`" :min="0" :precision="2" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="秒杀配额" required v-if="form.is_flash_sale==1">
								<a-input-number v-model="form.flash_num" :formatter="value => `${value} 个`" :min="0" :precision="0" placeholder="请输入" style="width: 160px;" />
							</a-form-model-item>
							
							<a-form-model-item label="秒杀时间" required v-if="form.is_flash_sale==1">
								<a-range-picker @change="changeFlashSaleTime" v-model="form.flash_sale_time"  format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" />
							</a-form-model-item> -->
							
							<a-form-model-item label="有效期" required >
								<div style="position: relative;width:210px;" class="flex alcenter">
									<a-input-number style="width:210px;" v-model="form.valid_time" :precision="0" :min="0" >
										
									</a-input-number>
									<a-select v-model="form.valid_unit" style="width: 70px" class="edit-snap-up-unit-addon">
										<a-select-option :value="'day'">
											天
										</a-select-option>
										<a-select-option :value="'month'">
											月
										</a-select-option>
										<a-select-option :value="'year'">
											年
										</a-select-option>
									</a-select>
								</div>
							</a-form-model-item>
							
							<a-form-model-item label="展示已售的数量" required >
								<a-input-number :formatter="value => `${value} 个`" v-model="form.fictitious_take_count" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<a-form-model-item label="排序" required help="数字越高,排的越高" >
								<a-input-number  v-model="form.sort" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<!-- <a-form-model-item required label="是否只有新用户才可以购买" help="注册一周内未购买任何次卡的用户视为新用户" >
								<a-radio-group v-model="form.is_new_can">
									<a-radio :value="1">是</a-radio>
									<a-radio :value="0">否</a-radio>
								</a-radio-group>
							</a-form-model-item> -->
							
							<a-form-model-item label="单用户可以购买的数量" required help="0表示不限制" >
								<a-input-number  v-model="form.can_buy_num" :min="0" :precision="0"
								 placeholder="请输入" />
							</a-form-model-item>
							
							<a-form-model-item required label="缩略图" >
								<div class="ft12 ftw400 cl-notice">建议上传400x300px</div>
								<div class="mt10 ml20">
									<upload-img v-model="form.cover_img"></upload-img>
								</div>
							</a-form-model-item>
							
							
							<a-form-model-item required label="详情页轮播图" >
								<div class="ft12 ftw400 cl-notice">最多可传5张，建议上传750x750px</div>
								<div class="mt10 ml20">
									<upload-list v-model="form.rotation" :num="5"></upload-list>
								</div>
							</a-form-model-item>
							
							<a-form-model-item required label="关联门店" >
								<a-spin :spinning="showProjectLoading">
									<a-button type="primary" icon="plus" ghost @click="aboutStore()">关联</a-button>
									<div class="mt16" v-if="storeInfo&&storeInfo.name">
										<div class="show-project flex">
											<div class="show-project-item " >
												<div class="flex space alcenter">
													<div class="flex alcenter">
														<img :src="storeInfo.photo[0]" v-if="storeInfo.photo&&storeInfo.photo.length>0"/>
														<div class="ml10">
															<div class="text-over4">
																 {{ storeInfo.name }}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div  class="ml20 flex alcenter" style="height: 80px;line-height: 1;">
												<i class="iconfont iconbtn_close ft20 cl-info clickAct" @click="delStoreInfo()"/>
											</div>
										</div>
									</div>
								</a-spin>
							</a-form-model-item>


							<a-form-model-item required label="关联商品" >
								<a-spin :spinning="showProjectLoading">
									<a-button type="primary" icon="plus" ghost @click="relationProjectAct()">关联</a-button>
									<div class="mt16" v-if="project_items.length>0">
										<div class="show-project flex " v-for="(item,index) in project_items">
											<div class="show-project-item " >
												<div class="flex space alcenter">
													<div class="flex alcenter">
														<img :src="item.cover_img"/>
														<div class="ml10">
															<div class="text-over4">
																{{item.abbreviation}}
															</div>
															<div class="flex alcenter cl-notice mt10">
																<div>门市原价：</div>
																<div class="text-line">¥{{item.market_price}}</div>
															</div>
														</div>
													</div>
													
													<div>
														<a-input-number v-model="form.project_items[index].num" :formatter="value => `${value} 次`" :min="0" :precision="0" placeholder="请输入" style="width: 100px;" />
													</div>
												</div>
											</div>
											
											<div  class="ml20 flex alcenter" style="height: 80px;line-height: 1;">
												<i class="iconfont iconbtn_close ft20 cl-info clickAct" @click="delProjectAct(index)"/>
											</div>
										</div>
									</div>
								</a-spin>
							</a-form-model-item>
							
							<a-form-model-item label="使用说明" required>
								<a-textarea  v-model="form.use_info" :rows="11" />
							</a-form-model-item>
							
							<a-form-model-item :wrapper-col="{ span: 2, offset: 1 }" class="mt20">
								<a-button type="primary" @click="onSubmit">
									保存上架
								</a-button>
						
							</a-form-model-item>
						</a-form-model>
					
				</div>
				
				<div v-if="relationProjectVisible">
					<relation-project v-model="form.project_items" :visible="relationProjectVisible" @cancel="cancelRelationProject" @ok="okRelationProject"></relation-project>
				</div>
			</div>
		</a-spin>
		
		<a-modal
			title="选择关联门店"
			:visible="storeShow"
			width='900px'
			:footer="null"
			@cancel="storeCancle"
		>
			<a-table rowKey="mendian_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="dataSource" :loading="loading">
			<template slot="names" slot-scope="record">
				<div class="name" @click="jump(record)">
				<!-- {{record.name}} -->
				{{record.name}}
				</div>
			</template>
			<template slot="photo" slot-scope="record">
				<div>
					<img class="swiper_item" :src="record[0]" alt="" style="width: 90px;height: 90px;">
				</div>
			</template>

			<template slot="action" slot-scope="record,index">
				<a class="menu-act" href="javascript:;" @click="chooseStore(record)">
					<span>选择门店</span>
				</a>
			</template>
			</a-table>
		</a-modal>

	</div>
</template>

<script>
	import uploadList from '../../components/upload/list.vue';
	import uploadImg from '../../components/upload/upload.vue';
	import relationProject from './components/EditOncecard/modal/relationProject.vue';
	export default{
		components:{
			uploadList,
			uploadImg,
			relationProject,
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				relationProjectVisible:false,
				showProjectLoading:false,
				labelCol: { span: 5 },
			    wrapperCol: { span: 19 },
				once_card_id:0,
				project_items:[],
				dataSource: [],
				form: {
					name:'',
					price:'',
					worth:'',
					is_flash_sale:0,
					flash_price:'',
					flash_num:'',
					flash_sale_time:[],
					valid_time:'',
					valid_unit:'day',
					fictitious_take_count:'',
					sort:'',
					is_new_can:0,
					can_buy_num:0,
					cover_img:'',
					rotation:[],
					once_card_details:[],
					project_items:[],
					use_info:'',
				},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				storeShow: false,
				columns: [
					{ title: "门店ID", dataIndex: "mendian_id", align: "center" },
					{
						title: "门店名称",
						key: "names",
						align: "center",
						scopedSlots: { customRender: "names" },
					},
					{
						title: "轮播图",
						dataIndex: "photo",
						align: "center",
						scopedSlots: { customRender: "photo" },
					},
					{ title: "门店地址", dataIndex: "address", align: "center" },
					{ title: "门店电话", dataIndex: "info", align: "center" },
					{ title: "介绍详情", dataIndex: "tel", align: "center" },
					{
						title: "操作",
						key: "action",
						align: "center",
						scopedSlots: { customRender: "action" },
					},
				],
				storeInfo: undefined
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.once_card_id=0;
			} else {
				this.once_card_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.once_card_id==0){
					return '添加次卡';
				}else{
					return '编辑次卡';
				}
			}
		},
		methods:{
			storeCancle(){
				this.storeShow = false
			},
			delStoreInfo(){
				this.storeInfo = undefined
			},
			chooseStore(item){
				console.log(item);
				this.storeInfo = item
				this.storeShow = false
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getMendianList();
			},
			getMendianList() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api("admin/getMendianList", {
					// keyword: this.search.keyword,
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then((res) => {
					this.pagination.total = res.total;
					this.dataSource = res.list;
					console.log(this.dataSource, "  this.dataSource ");
					this.loading = false;
				})
				.catch((res) => {
					console.log(res);
					this.loading = false;
				});
			},
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showOncecard',{
					once_card_id:this.once_card_id
				}).then(res=>{
					
					if(res.project_items){
						this.project_items=res.project_items;
					}
					if(res.detail){
						this.form=res.detail;
						this.storeInfo = {
							photo: JSON.parse(res.detail.mendian_photo),
							name: res.detail.mendian_name,
							mendian_id: res.detail.mendian_id,
						}
					}
					this.ProjectsInit();
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			
			changeFlashSaleTime(value){
				this.form.flash_sale_time=value;
			},
			
			onSubmit() {
				if(this.form.project_items.length<=0){
					this.$message.error('请选择项目');
					return;
				}
				
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/saveOncecard',{
					once_card_id:this.once_card_id,
					name:this.form.name,
					price:this.form.price,
					worth:this.form.worth,
					is_flash_sale:this.form.is_flash_sale,
					flash_price:this.form.flash_price,
					flash_num:this.form.flash_num,
					flash_sale_time:this.form.flash_sale_time,
					valid_time:this.form.valid_time,
					valid_unit:this.form.valid_unit,
					fictitious_take_count:this.form.fictitious_take_count,
					sort:this.form.sort,
					is_new_can:this.form.is_new_can,
					can_buy_num:this.form.can_buy_num,
					cover_img:this.form.cover_img,
					rotation: this.form.rotation,
					project_items:this.form.project_items,
					use_info:this.form.use_info,
					mendian_id: this.storeInfo?this.storeInfo.mendian_id:''
				}).then(res=>{
					this.$message.success('保存成功');
					this.$router.go(-1);
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			aboutStore(){
				this.storeShow = true
				this.getMendianList()
			},
			relationProjectAct(){
				this.relationProjectVisible=true;
			},
			cancelRelationProject(){
				this.relationProjectVisible=false;
			},
			okRelationProject(){
				// console.log(this.form.project_items);
				this.relationProjectVisible=false;
				this.getProject();
			},
			
			getProject(){
				if(this.form.project_items.length == 0) return; //如果数据为空不请求
				let project_ids = new Array;
				for(var a in this.form.project_items){
					project_ids.push(this.form.project_items[a].project_id);
				}
				if(this.showProjectLoading==true) return;
				this.showProjectLoading=true;
				this.$http.api('admin/getProjectByIdsFromOncecard',{
					project_ids:project_ids,
					limit:this.form.project_items.length,
					page:1,
				}).then(res=>{
					this.project_items = res.list;
					this.ProjectsInit();
					this.showProjectLoading=false;
				}).catch(res=>{
					console.log(res);
					this.showProjectLoading=false;
				});
			},
			
			ProjectsInit(){
				let project_items =JSON.parse(JSON.stringify(this.project_items));
				for(var i in project_items){
					for(var j in this.form.project_items){
						let status = (project_items[i].project_id==this.form.project_items[j].project_id)
						if(status){
							project_items[i].is_select=1;
							project_items[i].select_index=j;
							project_items[i].num=this.form.project_items[j].num;
						}
					}
				}
				this.project_items=project_items;
				console.log(this.project_items);
			},
			
			delProjectAct(index){
				let select_index=this.project_items[index].select_index;
				this.project_items.splice(index,1);
				this.form.project_items.splice(select_index,1);
			},
			
			
			
			
		}
	}

</script>

<style>
	.edit-once-card-project-it{
		width: 400px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	}
	
	.edit-once-card-project-not-it{
		width: 480px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		padding: 20px;
		line-height: 20px;
	}
	
	.edit-once-card-project-not-it img{
		width: 60px;
		height: 60px;
	}
	
	.edit-once-card-unit-addon{
		position: absolute;
		right: 0px;
	}
	
	
	
	.show-project{
		
	}
	
	.show-project-item{
		width: 480px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		margin-bottom: 10px;
		padding: 10px;
		line-height: 1;
	}
	
	.show-project-item-tag{
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}
	
	.show-project-item img{
		width: 80px;
		height: 60px;
	}
</style>